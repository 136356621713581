import { Button, Input, Loader, ReactSwitch } from "@storybook";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import "./app-management.scss";

export const AppManagement = () => {
  const [
    { isAppMaintenance, isPaymentMaintenance, isTreadingMaintenance,_id },
    setAppMaintenance,
  ] = useState({
    isAppMaintenance: false,
    isPaymentMaintenance: false,
    isTreadingMaintenance: false,
    _id: ""
  });

  const [
    { version, releaseNotes, assetUrl, buildNumber, isActive },
    setAppSilanceUpdate,
  ] = useState({
    version: "",
    releaseNotes: "",
    assetUrl: "",
    isActive: false,
    buildNumber: "",
  });
  const [isLoading,setLoading]= useState(true)

  const { getV1, postV1 , patch} = useNetwork();
  const { successNotification } = useNotification();

  const handleChangeSelect = (checked: boolean, name: string) => {
    setAppMaintenance((prev) => ({ ...prev, [name]: checked }));
  };
  const handleChangeSelectUpdate = (checked: boolean, name: string) => {
    setAppSilanceUpdate((prev) => ({ ...prev, [name]: checked }));
  };

  const handelInput = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    setAppSilanceUpdate((prev) => ({ ...prev, [name]: e.target.value }));
  };  

  const initSilance = async () => {
    const resp = await getV1(API_URL.bundleDetails);
    if (resp?.apiData?.message === "ok") {
      const { data } = resp?.apiData;
      setAppSilanceUpdate(data);
    }
    setLoading(false)
  };

  const initAppMaintenance = async () => {
    const resp = await getV1(`${API_URL.version}/123`);
    if (resp?.apiData?.message === "ok") {
      const { data } = resp?.apiData;
      setAppMaintenance(data);
    }
    setLoading(false)
  };

  useEffect(() => {
    initSilance();
    initAppMaintenance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveAppSilance = async () => {
    const payload = {
      version,
      releaseNotes,
      bundleUrl: assetUrl,
      buildNumber,
      isActive,
    };
    const resp = await postV1(API_URL.bundleDetails, payload);
    if (resp?.apiData?.message === "ok") {
      successNotification("Updated successfully");
    }
    
  };

  const onSaveAppMaintenance = async () => {
    const payload = {
      isAppMaintenance,
      isPaymentMaintenance,
      isTreadingMaintenance,
      updateType:'none'
    };
    
    const resp = await patch(`${API_URL.version}/${_id}`, payload);
    if (resp?.apiData?.message === "ok") {
      successNotification("Updated successfully");
    }
  };

  const appMaintenaceBody = useMemo(() => {
    return (
      <div className="AppManagement--container">
        <div className="AppManagement--header">
          <div className="AppManagement--left">
            <h4>App Maintenance</h4>
          </div>
          <div className="AppManagement--right">
            <Button
              label="Save"
              className={"button__outline AppManagement--btn"}
              handleClick={onSaveAppMaintenance}
            />
          </div>
        </div>
        <div className="AppManagement--body">
          <div className="AppManagement--info">
            <h6>App</h6>
            <ReactSwitch
              id="isAppMaintenance"
              checked={isAppMaintenance}
              handleChange={(checked: boolean) =>
                handleChangeSelect(checked, "isAppMaintenance")
              }
            />
          </div>
          <div className="AppManagement--info">
            <h6>Trading</h6>
            <ReactSwitch
              id="isTreadingMaintenance"
              checked={isTreadingMaintenance}
              handleChange={(checked: boolean) =>
                handleChangeSelect(checked, "isTreadingMaintenance")
              }
            />
          </div>
          <div className="AppManagement--info">
            <h6>Payment</h6>
            <ReactSwitch
              id="isPaymentMaintenance"
              checked={isPaymentMaintenance}
              handleChange={(checked: boolean) =>
                handleChangeSelect(checked, "isPaymentMaintenance")
              }
            />
          </div>
        </div>
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppMaintenance, isPaymentMaintenance, isTreadingMaintenance, _id]);

  const appSilanceUpdate = useMemo(() => {
    return (
      <div className="AppManagement--container">
        <div className="AppManagement--header">
          <div className="AppManagement--left">
            <h4>App Update</h4>
          </div>
          <div className="AppManagement--right">
            <Button
              label="Save"
              className={"button__outline AppManagement--btn"}
              handleClick={onSaveAppSilance}
            />
          </div>
        </div>
        <div className="AppManagement--body">
          <div className="AppManagement--info">
            <Input
              label="Version"
              inputType="text"
              placeholder="version"
              handleChange={(e) => handelInput(e, "version")}
              value={version}
            />
          </div>
          <div className="AppManagement--info">
            <Input
              label="Release Notes"
              inputType="text"
              placeholder="Release notes"
              value={releaseNotes}
              handleChange={(e) => handelInput(e, "releaseNotes")}
            />
          </div>
          <div className="AppManagement--info">
            <Input
              label="Asset Url"
              inputType="text"
              placeholder="Asset url"
              value={assetUrl}
              handleChange={(e) => handelInput(e, "assetUrl")}
            />
          </div>

          <div className="AppManagement--info">
            <Input
              label="Build Number"
              inputType="number"
              placeholder="Build number"
              value={buildNumber}
              handleChange={(e) => handelInput(e, "buildNumber")}
            />
          </div>
          <div className="AppManagement--info">
            <div>
              <h6>Active</h6>
              <ReactSwitch
                id="isAppMaintenance"
                checked={isActive}
                handleChange={(checked: boolean) =>
                  handleChangeSelectUpdate(checked, "isActive")
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetUrl, buildNumber, isActive, releaseNotes, version]);

  if(isLoading){
    return <Loader/>
  }
  return (
    <div className="AppManagement">
      <div className="AppManagement--wrapper">
        {appSilanceUpdate}
        {appMaintenaceBody}
      </div>
    </div>
  );
};
