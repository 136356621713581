import { useCallback, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ScrollableTable } from "./table";
import { formatFileSize } from "utils";
import { useUserPermission } from "hooks/user-permission";
import { FILE_EXTENSION_URL } from "../store/constant";
import { formatNumberWithCommas, useCurrency } from "hooks";
import ProgressLine from "./progressLine/progressLine";

export const AuctionDetail = ({
  selectedAsset,
  handelReject,
  handelApprove,
}: any) => {
  const [errorIndexes, setErrorIndexes] = useState<any[]>([]);
  const buttonDisable = useUserPermission();
  const progress = selectedAsset?.totalRaise ?? 0
  const maxRaise = selectedAsset?.raiseDetails?.targetRaise ?? 0
  const minRaise = selectedAsset?.raiseDetails?.minimumRaise ?? 0

  const handelView = useCallback((fileUrl: string | URL) => {
    window.open(fileUrl, "_blank");
  }, []);

  const handleImageError = (index: number) => {
    setErrorIndexes((prevErrorIndexes) => [...prevErrorIndexes, index]);
  };
  const {
    formatCurrencyWithBillion
  } = useCurrency();
  const handelApproveAction = useCallback(
    (status: string, e: any) => {
      if (status === "APPROVED") {
        handelApprove(e, selectedAsset);
      } else {
        handelReject(e, selectedAsset);
      }
    },
    [handelApprove, handelReject, selectedAsset]
  );
  
  return (
    <div className="auctionDetails">
      <h2>Private Stock</h2>
      <p>Review asset details and take necessary actions.</p>
      <div className="auction-img">
        <div className="">
          <div className="mint-icon">
            {errorIndexes.includes(0) ? (
              <div>{selectedAsset?.name?.charAt(0)}</div>
            ) : (
              <img
                src={selectedAsset["logo"]}
                onError={() => handleImageError(0)}
                alt={`Images ${0 + 1}`}
              />
            )}
          </div>
        </div>
        <div>
          <h2>{selectedAsset?.name}</h2>
          <p className="auction-description">{selectedAsset?.symbol}</p>
        </div>
      </div>

      <div className="auction-row">
        <div className="auction--row">
          <p>Max supply.</p>
          <h3>{formatNumberWithCommas(selectedAsset?.maxSupply)}</h3>
        </div>
        <div className="auction--row">
          <p>Price</p>
          <h3>${formatNumberWithCommas(selectedAsset?.price)}</h3>
        </div>
      </div>
      <div className="auction-row">
        <div className="auction--row">
          <p>Blockchain</p>
          <h3>Polygon</h3>
        </div>
        <div className="auction--row">
          <p>Date & Time</p>
          {/* <h3>{(selectedAsset?.createdAt)}</h3> */}
         <h3>
  {selectedAsset?.createdAt &&
    new Date(selectedAsset.createdAt).toLocaleString("en-US", {
      year: "numeric",
      month: "short", 
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, 
    }).replace(",", "")}
</h3>
        </div>
      </div>
      <div className="auction-row">
        <div className="auction--row">
         <p>Minimum Raise</p>
         <h3>{formatCurrencyWithBillion(selectedAsset?.raiseDetails?.minimumRaise,2)}</h3>
        </div>
        <div className="auction--row">
         <p>Target Raise</p>
         <h3>{formatCurrencyWithBillion(selectedAsset?.raiseDetails?.targetRaise, 2)}</h3>
        </div>
      </div>
      <div className="auction-row">
      <div className="progress-section">
      <ProgressLine progress={progress} minRaise={minRaise} targetRaise={maxRaise || 0} /> 
        </div>
      </div>
      <div className="auction-row">
      <div className="auction--row">
         <p> Raise Period</p>
         <h3>{selectedAsset?.raiseDetails?.timeDuration} {selectedAsset?.raiseDetails?.timeUnit}</h3>
        </div>
        </div>

      <h2 className="loan-details">Creators and royalty split</h2>

      <ScrollableTable />
      {selectedAsset?.dataRoom?.length > 0 && (
        <>
          <h2 className="supporting-doc">Files</h2>
          <p>Some text</p>
          <div className="support-doc">
            {selectedAsset?.dataRoom?.map((item: any, index: number) => (
              <>
                <div className="support-div">
                  <div className="support--doc">
                    {" "}
                    <div className="doc-img">
                    <img
                        src={
                          FILE_EXTENSION_URL[
                            item?.docName?.split(".").pop().toLowerCase()
                          ]
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <div className="file-name"
                        data-tooltip-id={`${item?.id}_${index}`}>{item?.docName}</div>
                      <div className="support--doc_size">
                        {formatFileSize(item?.fileSize, 2)}
                      </div>
                    </div>
                    <ReactTooltip
                      className="theme-tooltip"
                      id={`${item?.id}_${index}`}
                      place="top"
                      content={item?.docName}
                      style={{ zIndex: 101 }}
                    />
                  </div>
                  <div className="btn-view">
                    <button onClick={() => handelView(item?.fileUrl)}>
                      View
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      )}
      {selectedAsset?.status === "PENDING" && (
        <div className="action-btns">
          <button
            className={`reject ${buttonDisable && "button--disabled"}`}
            onClick={(e) => handelApproveAction("REJECTED", e)}
            disabled={buttonDisable}
          >
            Reject
          </button>
          <button
            className={`approve ${buttonDisable && "button--disabled"}`}
            onClick={(e) => handelApproveAction("APPROVED", e)}
            disabled={buttonDisable}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};
