import { atom } from "recoil";
import { Json } from "types";
import { ASSETS_STATUS } from "./constant";

export const assetListPrivateState = atom<Json>({
  key: "asset-list-private-state",
  default: {},
});

export const selectedAssetState = atom<Json>({
  key: "selected-asset-state",
  default: {},
});

export const assetListCall = atom<boolean>({
  key: "asset-list-call-state",
  default: false,
});


export const initalFilterStaate = atom<Json>({
  key: "initail-filter-state",
  default: {
    limit: 10,
    offset: 0,
    type: "privates",
    status: `${ASSETS_STATUS.PENDING},${ASSETS_STATUS.REJECTED}`,
  },
});

export const assetCounterChangeState = atom<Json>({
  key: "asset-counter-change-state",
  default: {
    private: false,
    sba: false,
    mhl:false,
    equipmentFinance:false,
  },
});
