import { useCallback, useEffect, useState } from "react";
import {
  createFormState,
  editRoleState,
  moduleListState,
  selectedRoleState,
  allAccessState,
} from "../store";
import { useRecoilState, useRecoilValue } from "recoil";

import { MODULE_ICON } from "./constant";
import { ReactSwitch } from "@storybook";

export const Permission = () => {
  const moduleList = useRecoilValue(moduleListState);
  const [createForm, setCreateForm] = useRecoilState(createFormState);
  const editRole = useRecoilValue(editRoleState);
  const selectedRole = useRecoilValue(selectedRoleState);
  const [allAccess, setAllAccess] = useRecoilState(allAccessState);

  const [collapseRoute, setCollapseRoute] = useState<any>(new Set([]));

  const handleToggle = (key: string) => {
    if (collapseRoute.has(key)) {
      collapseRoute.delete(key);
    } else {
      collapseRoute.add(key);
    }

    setCollapseRoute(new Set([...collapseRoute]));
  };
  useEffect(() => {
    if (editRole) {
      setAllAccess(selectedRole?.hasAllAccess);
    }
  }, [editRole, selectedRole?.hasAllAccess, setAllAccess]);

  // Auto on  all access we will do it later
  // const [allPayload, setAllPayload] = useState<any[]>([]);

  // const allAccessPayload = useCallback(() => {
  //   const permissions: any[] = [];
  //   const selectedPermission = moduleList;
  //   for (let i = 0; i < selectedPermission?.length; i++) {
  //     const subModuleModify = [];
  //     const selectedPermissionMdule = selectedPermission[i];
  //     for (let j = 0; j < selectedPermissionMdule?.subModules?.length; j++) {
  //       const subModuleObj = {
  //         create: true,
  //         view: true,
  //         edit: true,
  //         subModuleId: selectedPermissionMdule?.subModules[j]?._id,
  //       };
  //       subModuleModify.push(subModuleObj);
  //     }

  //     const permissionModify = {
  //       create: true,
  //       view: true,
  //       edit: true,
  //       module: selectedPermission[i]?._id,
  //       subModules: subModuleModify,
  //     };

  //     permissions?.push(permissionModify);
  //   }
  //   setAllPayload(permissions);
  // }, [moduleList]);

  // useEffect(() => {
  //   allAccessPayload();
  // }, [allAccessPayload]);

  const handelAllAccess = useCallback(() => {
    setAllAccess(!allAccess);

    const permissions: any[] = [];
    const selectedPermission = moduleList;

    for (let i = 0; i < selectedPermission?.length; i++) {
      const subModuleModify = [];
      const selectedPermissionMdule = selectedPermission[i];
      for (let j = 0; j < selectedPermissionMdule?.subModules?.length; j++) {
        const subModuleObj = {
          create: !allAccess ? true : false,
          view: !allAccess ? true : false,
          edit: !allAccess ? true : false,
          subModuleId: selectedPermissionMdule?.subModules[j]?._id,
        };
        subModuleModify.push(subModuleObj);
      }

      const permissionModify = {
        create: !allAccess ? true : false,
        view: !allAccess ? true : false,
        edit: !allAccess ? true : false,
        module: selectedPermission[i]?._id,
        subModules: subModuleModify,
      };

      permissions?.push(permissionModify);
    }

    setCreateForm((prev: any) => ({
      ...prev,
      permissions: permissions,
    }));
  }, [allAccess, moduleList, setCreateForm]);

  const handelAllAccessEdit = useCallback(() => {
    setAllAccess(!allAccess);

    const permissions: any[] = [];
    const selectedPermission = selectedRole?.permissions;

    for (let i = 0; i < selectedPermission?.length; i++) {
      const subModuleModify = [];
      const selectedPermissionMdule = selectedPermission[i]?.module;
      for (let j = 0; j < selectedPermissionMdule?.subModules?.length; j++) {
        const subModuleObj = {
          create: !allAccess
            ? true
            : selectedPermissionMdule?.subModules[j]?.create ??
              selectedPermission[i]?.create,
          view: !allAccess
            ? true
            : selectedPermissionMdule?.subModules[j]?.view ??
              selectedPermission[i]?.view,
          edit: !allAccess
            ? true
            : selectedPermissionMdule?.subModules[j]?.edit ??
              selectedPermission[i]?.edit,
          subModuleId: selectedPermissionMdule?.subModules[j]?._id,
        };
        subModuleModify.push(subModuleObj);
      }

      const permissionModify = {
        create: !allAccess ? true : selectedPermission[i]?.create,
        view: !allAccess ? true : selectedPermission[i]?.view,
        edit: !allAccess ? true : selectedPermission[i]?.edit,
        module: selectedPermission[i]?.module?._id,
        subModules: subModuleModify,
      };
      permissions?.push(permissionModify);
    }

    setCreateForm((prev: any) => ({
      ...prev,
      permissions: permissions,
    }));
  }, [allAccess, selectedRole?.permissions, setCreateForm]);

  const checkBoxValueView = useCallback(
    (id: number) => {
      const Index = createForm?.permissions?.findIndex(
        (el: any) => el.module === id
      );
      if (
        createForm?.permissions?.[Index]?.view &&
        !createForm?.permissions?.[Index]?.create
      ) {
        return true;
      } else {
        return false;
      }
    },
    [createForm?.permissions]
  );

  const checkBoxValueAll = useCallback(
    (id: number) => {
      const Index = createForm?.permissions?.findIndex(
        (el: any) => el.module === id
      );

      if (
        createForm?.permissions?.[Index]?.view &&
        createForm?.permissions?.[Index]?.create
      ) {
        return true;
      } else {
        return false;
      }
    },
    [createForm?.permissions]
  );

  const handleCheckBox = useCallback(
    (
      e: any,
      moduleId: number,
      subModule = false,
      viewOnly = false,
      subModuleId = 0
    ) => {
      const { checked } = e.target;
      const permission: any = {
        module: moduleId,
        view: checked,
        edit: viewOnly ? false : checked,
        create: viewOnly ? false : checked,
        subModules: [],
      };
      const subModules = {
        subModuleId: subModuleId,
        view: checked,
        edit: viewOnly ? false : checked,
        create: viewOnly ? false : checked,
      };
      // const IndexArray = moduleList?.findIndex(
      //   (el: { _id: number }) => el._id === moduleId
      // );

      if (subModule) {
      }
      //   moduleList[IndexArray].forEach((element: { _id: any; }) => {
      //     const newSubModule = {
      //       subModuleId: element._id,
      //       view: checked,
      //       edit: viewOnly ? false : checked,
      //       create: viewOnly ? false : checked,
      //     };

      //     permission.subModules.push(newSubModule);
      //   })

      // }
      const newPermissions = structuredClone(createForm.permissions) ?? [];

      const ModuleIndex = moduleList.findIndex(
        (el: { _id: number }) => el?._id === moduleId
      );

      if (
        ModuleIndex !== -1 &&
        moduleList[ModuleIndex]?.subModules?.length > 0 &&
        subModuleId === 0
      ) {
        moduleList[ModuleIndex]?.subModules?.forEach((item: any) => {
          permission.subModules.push({
            subModuleId: item._id,
            view: checked,
            edit: viewOnly ? false : checked,
            create: viewOnly ? false : checked,
          });
        });
      }
      const Index = newPermissions.findIndex(
        (el: { module: number }) => el.module === moduleId
      );

      const subIndex = newPermissions[Index]?.subModules?.findIndex(
        (el: { subModuleId: number }) => el.subModuleId === subModuleId
      );
      if (Index !== -1) {
        if (subModuleId) {
          if (subIndex !== -1 && subIndex !== undefined) {
            newPermissions[Index]?.subModules.splice(subIndex, 1);

            newPermissions[Index]?.subModules.push(subModules);
            newPermissions[Index].view = false;
            newPermissions[Index].create = false;
            newPermissions[Index].edit = false;
            if (checked) {
              if (
                newPermissions[Index]?.subModules.length > 0 &&
                newPermissions[Index]?.subModules?.length ===
                  moduleList[ModuleIndex]?.subModules?.length
              ) {
                const SubmoduleView = newPermissions[Index]?.subModules?.filter(
                  (item: any) => item.view === false
                );
                const SubmoduleAll = newPermissions[Index]?.subModules?.filter(
                  (item: any) => item.edit === false && item.view === true
                );
                if (SubmoduleView.length === 0 && SubmoduleAll.length === 0) {
                  newPermissions[Index].view = true;
                  newPermissions[Index].create = true;
                  newPermissions[Index].edit = true;
                } else if (
                  SubmoduleAll.length ===
                  newPermissions[Index]?.subModules?.length
                ) {
                  newPermissions[Index].view = true;
                  newPermissions[Index].create = false;
                  newPermissions[Index].edit = false;
                }
              }
            }
          } else {
            newPermissions[Index]?.subModules.push(subModules);
            newPermissions[Index].view = false;
            newPermissions[Index].create = false;
            newPermissions[Index].edit = false;
            if (checked) {
              if (
                newPermissions[Index]?.subModules.length > 0 &&
                newPermissions[Index]?.subModules?.length ===
                  moduleList[ModuleIndex]?.subModules?.length
              ) {
                const SubmoduleView = newPermissions[Index]?.subModules?.filter(
                  (item: any) => item.view === false
                );
                const SubmoduleAll = newPermissions[Index]?.subModules?.filter(
                  (item: any) => item.edit === false && item.view === true
                );
                if (SubmoduleView.length === 0 && SubmoduleAll.length === 0) {
                  newPermissions[Index].view = true;
                  newPermissions[Index].create = true;
                  newPermissions[Index].edit = true;
                } else if (
                  SubmoduleAll.length ===
                  newPermissions[Index]?.subModules?.length
                ) {
                  newPermissions[Index].view = true;
                  newPermissions[Index].create = false;
                  newPermissions[Index].edit = false;
                }
              }
            }
          }
        } else {
          newPermissions.splice(Index, 1); //submodule
          newPermissions.push(permission);
        }
      } else if (subModuleId && moduleId) {
        const payload = {
          module: moduleId,
          subModules: [subModules],
        };
        const Index = newPermissions?.length;
        newPermissions.push(payload);
        newPermissions[Index].view = false;
        newPermissions[Index].create = false;
        newPermissions[Index].edit = false;
        if (checked) {
          if (
            newPermissions[Index]?.subModules.length > 0 &&
            newPermissions[Index]?.subModules?.length ===
              moduleList[ModuleIndex]?.subModules?.length
          ) {
            const SubmoduleView = newPermissions[Index]?.subModules?.filter(
              (item: any) => item.view === false
            );
            const SubmoduleAll = newPermissions[Index]?.subModules?.filter(
              (item: any) => item.edit === false && item.view === true
            );
            if (SubmoduleView.length === 0 && SubmoduleAll.length === 0) {
              newPermissions[Index].view = true;
              newPermissions[Index].create = true;
              newPermissions[Index].edit = true;
            } else if (
              SubmoduleAll.length === newPermissions[Index]?.subModules?.length
            ) {
              newPermissions[Index].view = true;
              newPermissions[Index].create = false;
              newPermissions[Index].edit = false;
            }
          }
        }
      } else {
        newPermissions.push(permission);
      }
      setCreateForm((prev: any) => ({
        ...prev,
        permissions: newPermissions,
      }));
    },
    [createForm.permissions, moduleList, setCreateForm]
  );
  const submoduleValueAll = useCallback(
    (id: number, subModuleId: any) => {
      const Index = createForm?.permissions?.findIndex(
        (el: { module: number }) => el.module === id
      );
      if (createForm?.permissions?.[Index]) {
        const subModuleIndex = createForm?.permissions[
          Index
        ]?.subModules?.findIndex(
          (el: { subModuleId: any }) => el.subModuleId === subModuleId
        );
        if (
          createForm?.permissions?.[Index]?.subModules?.[subModuleIndex]
            ?.view &&
          createForm?.permissions?.[Index]?.subModules?.[subModuleIndex]?.create
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [createForm.permissions]
  );

  const submoduleValueView = useCallback(
    (id: number, subModuleId: any) => {
      const Index = createForm?.permissions?.findIndex(
        (el: { module: number }) => el.module === id
      );

      if (createForm?.permissions?.[Index]) {
        const subModuleIndex = createForm?.permissions[
          Index
        ]?.subModules?.findIndex(
          (el: { subModuleId: any }) => el.subModuleId === subModuleId
        );
        if (
          createForm?.permissions?.[Index]?.subModules?.[subModuleIndex]
            ?.view &&
          !createForm?.permissions?.[Index]?.subModules?.[subModuleIndex]
            ?.create
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [createForm.permissions]
  );

  return (
    <>
      <div className="permissions">
        <h3>Permissions</h3>
        <p>Select permissions you want to give it to this role</p>
        <div className="all-access">
          <div>
            <h4>All access</h4>
            <p>
              Enable this option to grant this role unrestricted access to all
              available permissions. When turned on, individual permissions
              below will be disabled.
            </p>
          </div>
          <ReactSwitch
            id={""}
            checked={allAccess}
            handleChange={
              editRole && allAccess ? handelAllAccessEdit : handelAllAccess
            }
          />
        </div>
        <div className="permission-header permission-row">
          <div>Actions</div>
          <div>View only</div>
          <div>Create, edit & view</div>
        </div>
        {moduleList?.map((module: any) => {
          return (
            <div>
              <div
                className={`permission-row permission--header ${
                  module.subModules.length > 0 && "permission-active"
                }`}
              >
                <div onClick={() => handleToggle(module)}>
                  {module.subModules.length > 0 ? (
                    <>
                      {collapseRoute.has(module) ? (
                        <i className="ri-arrow-up-s-fill dropdown-arrow"></i>
                      ) : (
                        <i className="ri-arrow-down-s-fill dropdown-arrow"></i>
                      )}
                    </>
                  ) : (
                    <i className="ri-empty"></i>
                  )}

                  <i className={`${MODULE_ICON[module.name]} module_icon`}></i>
                  {module.name}
                </div>

                <div>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleCheckBox(
                        e,
                        module._id,
                        module.subModules.length > 0,
                        true
                      )
                    }
                    name={module.name}
                    checked={checkBoxValueView(module._id)}
                    disabled={allAccess}
                  />
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleCheckBox(
                        e,
                        module._id,
                        module.subModules.length > 0,
                        false
                      )
                    }
                    name={module.name}
                    checked={allAccess ? true : checkBoxValueAll(module._id)}
                    disabled={allAccess}
                  />
                </div>
              </div>
              {module?.subModules?.map((submodule: any) => {
                return (
                  <>
                    {collapseRoute.has(module) && (
                      <div className="permission-row">
                        <div>{submodule?.name === "EQUIPMENT FINANCE" ? "Equipment Finance" : submodule?.name}</div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCheckBox(
                                e,
                                module._id,
                                false,
                                true,
                                submodule._id
                              )
                            }
                            name={submodule.name}
                            checked={submoduleValueView(
                              module._id,
                              submodule._id
                            )}
                            disabled={allAccess}
                          />
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCheckBox(
                                e,
                                module._id,
                                false,
                                false,
                                submodule._id
                              )
                            }
                            name={submodule.name}
                            checked={
                              allAccess
                                ? true
                                : submoduleValueAll(module._id, submodule._id)
                            }
                            disabled={allAccess}
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};
